.App {
  text-align: center;
}

.App-logo {
  height: 100px;
  width: 100px;
  pointer-events: none;
}

.App-header-logo {
  height: 100px;
  width: 200px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 200px;
  display: flex;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-button-group {
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 20px;
}

.Color-picker-container {
  margin-top: 16px;
}

.realistic-chooser {
  width: 100%;
}

.Rawrlex-container {
  display: flex;
  width: 500px;
  position: absolute;
  top: 100px;
  left: 400px;
  justify-content: center;
  align-items: center;
}

.dashed-container {
  padding: 15px;
  width: 100px;
  height: 1000px;
}

.kids-container {
  width: 50px;
  height: 700px;
}

.dino-option {
  display: flex;
  justify-content: center;
  align-items: center;
}

.solid-container,
.img-band-container {
  height: 99%;
  overflow: hidden;
}

.img-band-container {
  position: absolute;
  left: -38px;
  top: 0;
}

.img-band {
  height: 100%;
}

.watch-face {
  position: absolute;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.Rawrlex-dino-img {
}

.Watch-style-options,
.watch-face-styles,
.watch-band-size {
  display: flex;
  width: 200px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 16px;
}

.option-container,
.face-option-container {
  width: 75px;
  height: 75px;
  padding: 4px;
  overflow: hidden;
  margin-top: 12px;
  cursor: pointer;
}

.option-container {
  border-radius: 50%;
}

.option,
.face-shape-square,
.face-shape-round-square,
.face-shape-circle {
  height: 75px;
  width: 75px;
  cursor: pointer;
  overflow: hidden;
}

.option,
.face-shape-circle {
  border-radius: 50%;
}

.face-shape-square {
  border-radius: 0;
}

.face-shape-round-square {
  border-radius: 20px;
}

.option-selected,
.face-option-container {
  border: 2px solid black;
}

.face-option-selected {
  background-color: black;
}

.grid {
  height: 75px;
  width: 75px;
}

.row {
  height: 25px;
  width: 100%;
  display: flex;
}

.cell {
  height: 100%;
  width: 100%;
}

.watch-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 33px;
  width: 100%;
  height: 100%;
}

.kids-container .watch-grid {
  grid-auto-rows: 17px;
}

.kids-container .img-band-container {
  left: 45px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media print {
  .App-button-group {
    display: none;
  }
}
